import { EventAggregator }                   from 'aurelia-event-aggregator';
import { inject }                            from 'aurelia-framework';
import { I18N }                              from 'aurelia-i18n';
import { BooleanStatus }                     from 'modules/administration/models/boolean-status';
import { AggControlTypesRepository }         from 'modules/aggregates/lab/services/control-types-repository';
import { ApprovedWorkingFormulasRepository } from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/services/repository';
import { ConstructionFilterFormSchema }      from 'modules/bituminous-mixtures/mixtures/bm-tests/tab-schemas/filter-construction-schema';
import { DynamicListsRepository }            from 'modules/dynamic-lists/services/repository';
import { BituminousApplicationsRepository }  from 'modules/management/bituminous-mixtures/bituminous-applications/services/repository';
import { LayersRepository }                  from 'modules/management/bituminous-mixtures/layers/services/repository';
import { HighwaysRepository }                from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository }       from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { OEDatasRepository }                 from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository }        from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { PkTypesRepository }                 from 'modules/management/concessions/concessions-tree/pk-types/services/repository';
import { EntitiesRepository }                from 'modules/management/concessions/entities/services/repository';
import { TeamsRepository }                   from 'modules/management/concessions/teams/services/repository';
import { FieldsRepository }                  from 'modules/management/dynamic-fields/association-sectors/services/fields-repository';
import { PartZoneApplicationsRepository }    from 'modules/management/dynamic-fields/part-zone-applications/services/repository';
import { SampleCollectionsRepository }       from 'modules/management/dynamic-fields/sample-collections/services/repository';
import { ConcessionsRepository }             from 'modules/management/specifications/concessions/services/repository';
import { ListingRevisionsRepository }        from 'modules/management/specifications/listings/listing-revisions/services/repository';
import { ListingsRepository }                from 'modules/management/specifications/listings/services/repository';
import { SectorsRepository }                 from 'modules/management/specifications/sectors/services/repository';
import { RevisionMaterialTypesRepository }   from 'modules/management/specifications/specifications/specification-revisions/specification-revision-material-types/services/repository';
import { AppContainer }                      from 'resources/services/app-container';
import { SessionStorage }                    from 'resources/services/session-storage';

@inject(AppContainer, SessionStorage, EventAggregator, I18N, BituminousApplicationsRepository, PartZoneApplicationsRepository, SampleCollectionsRepository, RevisionMaterialTypesRepository, ApprovedWorkingFormulasRepository, AggControlTypesRepository, SectorsRepository, DynamicListsRepository, ConcessionsRepository, EntitiesRepository, TeamsRepository, HighwaysRepository, InterventionTypesRepository, FieldsRepository, ListingsRepository, ListingRevisionsRepository, LotInterventionsRepository, RevisionMaterialTypesRepository, LayersRepository, OEDatasRepository, PkTypesRepository)
export class FilterFormSchema extends ConstructionFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:                   [],
        created_at_from:              null,
        created_at_to:                null,
        test_bulletins:               [],
        full_general_columns:         [],
        full_test_bulletins:          [],
        full_bulletin_fields_columns: [],
        bulletin_fields_columns:      [],
    };

    constructor(appContainer, storage, eventAggregator, i18n, bitApplicationsRepository, partZoneApplicationsRepository, sampleCollectionsRepository, revisionMaterialTypesRepository, approvedWorkingFormulasRepository, controlTypesRepository, sectorsRepository, dynamicListsRepository, concessionsRepository, entitiesRepository, teamsRepository, highwaysRepository, interventionTypesRepository, fieldsRepository, listingsRepository, listingRevisionsRepository, lotInterventionsRepository, materialTypesRepository, layersRepository, oeDatasRepository, pkTypesRepository) {
        super(storage, eventAggregator, i18n, oeDatasRepository, pkTypesRepository);

        this.appContainer                      = appContainer;
        this.notifier                          = appContainer.notifier;
        this.bitApplicationsRepository         = bitApplicationsRepository;
        this.pzaRepository                     = partZoneApplicationsRepository;
        this.sampleCollectionsRepository       = sampleCollectionsRepository;
        this.materialTypesRepository           = materialTypesRepository;
        this.dynamicListsRepository            = dynamicListsRepository;
        this.controlTypesRepository            = controlTypesRepository;
        this.concessionsRepository             = concessionsRepository;
        this.sectorsRepository                 = sectorsRepository;
        this.entitiesRepository                = entitiesRepository;
        this.approvedWorkingFormulasRepository = approvedWorkingFormulasRepository;
        this.teamsRepository                   = teamsRepository;
        this.highwaysRepository                = highwaysRepository;
        this.interventionTypesRepository       = interventionTypesRepository;
        this.fieldsRepository                  = fieldsRepository;
        this.listingsRepository                = listingsRepository;
        this.listingRevisionsRepository        = listingRevisionsRepository;
        this.lotInterventionsRepository        = lotInterventionsRepository;
        this.materialTypesRepository           = materialTypesRepository;
        this.layersRepository                  = layersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param isBuilder
     * @returns {*[]}
     */
    async schema(viewModel, isBuilder = false) {

        // Define Construction fields filters
        super.schema(viewModel);

        this.lot_construction_id.size = 3;
        this.branch_id.size           = 3;
        this.edge_id.size             = 3;
        this.collection_pk.size       = 4;
        this.pk_type_id.size          = 2;

        this.test_number_from = {
            type:     'number',
            key:      'test_number_from',
            label:    viewModel.type === 'lab' ? 'form.field.sample-number(from)' : 'form.field.test-number(from)',
            size:     2,
            required: false,
        };

        this.test_number_to = {
            type:     'number',
            key:      'test_number_to',
            label:    viewModel.type === 'lab' ? 'form.field.sample-number(to)' : 'form.field.test-number(to)',
            size:     2,
            required: false,
        };

        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         3,
            required:     false,
            remoteSource: () => this.concessionsRepository.allByLoggedUser(),
            observers:    [
                async (newValue, oldValue) => {
                    this.dynamicListsRepository.testBulletins({ type: viewModel.type, ...viewModel.filterModel }).then((response) => {
                        this.test_bulletins.options = response;
                    });

                    if (!newValue) {
                        viewModel.filterModel.highway_id           = null;
                        viewModel.filterModel.intervention_type_id = null;
                    }

                    await this.highwaysRepository.lotGroupedAll({
                        concession_id: newValue,
                    }).then((response) => {
                        this.highway_id.options = this.highway_id.instance.transformOptionsObject(response);
                    });

                    await this.lot_intervention_id.instance.fetchData();
                },
            ],
        };

        this.entity_id = {
            type:         'select2',
            key:          'entity_id',
            label:        'form.field.entity',
            size:         3,
            remoteSource: () => this.entitiesRepository.all(),
            required:     false,
            attributes:   {
                disabled: false,
            },
            observers:    [
                (newValue) => {
                    this.team_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.entitiesRepository.teams(newValue).then((response) => {
                            this.team_id.options = response;
                            this.team_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.team_id = {
            type:         'select2',
            key:          'team_id',
            label:        'form.field.team',
            size:         2,
            remoteSource: () => (viewModel.filterModel.entity_id)
                ? this.entitiesRepository.teams(viewModel.filterModel.entity_id)
                : Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: !(viewModel.filterModel.entity_id),
            },
        };

        this.highway_id = {
            type:         'select2',
            key:          'highway_id',
            label:        'form.field.highway-road',
            size:         6,
            required:     false,
            remoteSource: () => this.highwaysRepository.lotGroupedAll({
                concession_id: viewModel.filterModel.concession_id,
            }),
            observers:    [
                (newValue, oldValue) => {
                    this.dynamicListsRepository.testBulletins({ type: viewModel.type, ...viewModel.filterModel }).then((response) => {
                        this.test_bulletins.options = response;
                    });

                    this.intervention_type_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.highwaysRepository.interventionTypes(newValue, { filter_by_logged_user: true }).then((response) => {
                            this.intervention_type_id.options = response;
                            this.intervention_type_id.instance.enable(response.length);
                        });
                    } else {
                        this.intervention_type_id.options = [{ id: null, name: this.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }];
                    }
                },
            ],
        };

        this.intervention_type_id = {
            type:         'select2',
            key:          'intervention_type_id',
            label:        'form.field.intervention-type',
            size:         6,
            required:     false,
            remoteSource: () => {
                return viewModel.filterModel.highway_id
                    ? this.highwaysRepository.interventionTypes(viewModel.filterModel.highway_id, { filter_by_logged_user: true })
                    : Promise.resolve([{ id: null, name: this.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }]);
            },
            observers:    [
                (newValue, oldValue) => {
                    this.dynamicListsRepository.testBulletins({ type: viewModel.type, ...viewModel.filterModel }).then((response) => {
                        this.test_bulletins.options = response;
                    });

                    return this.lotInterventionsRepository.lotGroupedAll({
                        concession_id:        viewModel.filterModel.concession_id,
                        highway_id:           viewModel.filterModel.highway_id,
                        intervention_type_id: newValue,
                    }).then((response) => {
                        this.lot_intervention_id.options = this.lot_intervention_id.instance.transformOptionsObject(response);
                    });
                },
            ],
        };

        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         6,
            required:     false,
            remoteSource: () => this.lotInterventionsRepository.lotGroupedAll({
                concession_id:        viewModel.filterModel.concession_id,
                highway_id:           viewModel.filterModel.highway_id,
                intervention_type_id: viewModel.filterModel.intervention_type_id,
            }),
            observers:    [
                (newValue) => {
                    this.dynamicListsRepository.testBulletins({ type: viewModel.type, ...viewModel.filterModel }).then((response) => {
                        this.test_bulletins.options = response;
                    });

                    this.lot_construction_id.instance.disable(!newValue);
                    this.lot_construction_id.instance.fetchData();

                    if (newValue) {
                        this.dynamicListsRepository.testBulletins({ type: viewModel.type, ...viewModel.filterModel }).then((response) => {
                            this.test_bulletins.options = response;
                        });
                    }
                },
            ],
        };

        this.external_reference = {
            type:     'text',
            key:      'external_reference',
            label:    'form.field.external-reference',
            size:     6,
            required: false,
        };

        this.material_type_id = {
            type:         'select2',
            key:          'material_type_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.material-type',
            remoteSource: () => this.materialTypesRepository.groupedAll(),
            size:         3,
            required:     false,
        };

        this.date_from = {
            type:     'material-ui-date-picker',
            key:      'date_from',
            label:    'form.field.collection-date(from)',
            size:     3,
            required: false,
        };

        this.date_to = {
            type:     'material-ui-date-picker',
            key:      'date_to',
            label:    'form.field.collection-date(to)',
            size:     3,
            required: false,
        };

        this.bm_approved_working_formula_id = {
            type:         'select2',
            key:          'bm_approved_working_formula_id',
            label:        'form.field.approved-working-formula-num',
            size:         4,
            remoteSource: () => this.approvedWorkingFormulasRepository.validatedByCurrentLotIntervention(),
            required:     false,
        }

        this.control_type_id = {
            type:         'select2',
            key:          'control_type_id',
            label:        'form.field.control_type',
            remoteSource: () => this.controlTypesRepository.all(),
            size:         2,
            required:     false,
            observers:    [
                (newValue) => {
                    this.sample_collection_id.instance.fetchData();
                },
            ],
        };

        this.sample_collection_id = {
            type:         'select2',
            key:          'sample_collection_id',
            label:        'form.field.sample-collection',
            remoteSource: () => this.sampleCollectionsRepository.allBySector({ sector_id: viewModel.filterModel.sector_id, control_type_id: viewModel.filterModel.control_type_id }),
            size:         3,
            required:     false,
            observers:    [
                (newValue) => {
                    this.pzaRepository.allBySector({ sector_id: viewModel.filterModel.sector_id, sample_collection_id: viewModel.filterModel.sample_collection_id }).then((response) => {
                        this.part_zone_application_id.options = response;
                    });
                },
            ],
        };

        this.part_zone_application_id = {
            type:         'select2',
            key:          'part_zone_application_id',
            label:        'form.field.part-zone-application',
            remoteSource: () => this.pzaRepository.allBySector({ sector_id: viewModel.filterModel.sector_id, sample_collection_id: viewModel.filterModel.sample_collection_id }),
            required:     false,
            size:         3,
        };

        this.layer_id = {
            type:         'select2',
            key:          'layer_id',
            label:        'form.field.layer',
            remoteSource: () => this.layersRepository.allBySector({ sector_id: viewModel.filterModel.sector_id }),
            size:         3,
            required:     false,
        };

        this.sector_id = {
            type:         'select2',
            key:          'sector_id',
            label:        'form.field.type',
            size:         3,
            remoteSource: () => this.sectorsRepository.byType(viewModel.type),
            required:     false,
            observers:    [
                (newValue) => {
                    viewModel.filterModel.full_general_columns         = [];
                    viewModel.filterModel.full_bulletin_fields_columns = [];
                    viewModel.filterModel.full_test_bulletins          = [];

                    viewModel.filterModel.general_columns.splice(0, viewModel.filterModel.general_columns.length);
                    viewModel.filterModel.bulletin_fields_columns.splice(0, viewModel.filterModel.bulletin_fields_columns.length);
                    viewModel.filterModel.test_bulletins.splice(0, viewModel.filterModel.test_bulletins.length);

                    this.dynamicListsRepository.testBulletins({ type: viewModel.type, ...viewModel.filterModel }).then((response) => {
                        this.test_bulletins.options          = response;
                        this.general_columns.options         = [];
                        this.bulletin_fields_columns.options = [];
                    });

                    this.sample_collection_id.instance.fetchData();
                    this.part_zone_application_id.instance.fetchData();
                    this.layer_id.instance.fetchData();
                    this.application_id.instance.fetchData();
                },
            ],
        };

        this.listing_revision_id = {
            type:         'select2',
            key:          'listing_revision_id',
            label:        'form.field.listings',
            size:         6,
            remoteSource: () => this.listingsRepository.allFromSector(viewModel.sectorId, {
                lot_intervention_id: viewModel.lot_intervention_id,
            }),
            required:     false,
            observers:    [
                (newValue) => {
                    this.bulletin_id.instance.disable(!newValue)

                    if (newValue) {
                        this.listingRevisionsRepository.getBulletins(newValue).then((response) => {
                            this.bulletin_id.options = response;
                            this.bulletin_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.bulletin_id = {
            type:         'select2',
            key:          'bulletin_id',
            label:        'form.field.tests',
            size:         6,
            remoteSource: () => Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.validation_status = {
            type:     'boolean-options-select',
            key:      'validation_status',
            label:    'form.field.validation-status',
            size:     3,
            required: false,
        };

        this.inspection_status = {
            type:     'boolean-options-select',
            key:      'inspection_status',
            label:    'form.field.inspection-status',
            size:     3,
            required: false,
        };

        this.concessionary_status = {
            type:     'boolean-options-select',
            key:      'concessionary_status',
            label:    'form.field.concessionary-status',
            size:     3,
            required: false,
        };

        this.application_id = {
            type:         'select2',
            key:          'application_id',
            label:        'form.field.application',
            remoteSource: () => this.bitApplicationsRepository.allBySector({ sector_id: viewModel.filterModel.sector_id }),
            size:         3,
            required:     false,
        };

        this.created_by = {
            type:     'user-entity-team-lot-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     4,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     4,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     4,
            required: false,
        };

        this.test_bulletins = {
            type:         'select-box',
            key:          'test_bulletins',
            label:        'form.field.chose-tests',
            size:         12,
            required:     false,
            remoteSource: () => this.dynamicListsRepository.testBulletins({ type: viewModel.type, ...viewModel.filterModel }),
            observers:    [
                (newValue) => {
                    if (newValue.length) {
                        viewModel.filterModel.full_test_bulletins = this.test_bulletins.instance.getSelectedOptions();

                        this.dynamicListsRepository.generalColumns({ listing_ids: viewModel.filterModel.full_test_bulletins.map(option => option.listing_id) }).then((response) => {
                            this.general_columns.options               = response;
                            viewModel.filterModel.full_general_columns = [];
                            viewModel.filterModel.general_columns.splice(0, viewModel.filterModel.general_columns.length);
                        });

                        this.dynamicListsRepository.testBulletinsFields({ bulletins_ids: viewModel.filterModel.full_test_bulletins.map(option => option.id) }).then((response) => {
                            this.bulletin_fields_columns.options               = response;
                            viewModel.filterModel.full_bulletin_fields_columns = [];
                            viewModel.filterModel.bulletin_fields_columns.splice(0, viewModel.filterModel.bulletin_fields_columns.length);
                        });
                    }
                },
            ],
        };

        this.general_columns = {
            type:         'select-box',
            key:          'general_columns',
            label:        'form.field.general-information',
            size:         6,
            required:     false,
            remoteSource: () => {
                return viewModel.filterModel.full_test_bulletins && viewModel.filterModel.full_test_bulletins.length > 0
                    ? this.dynamicListsRepository.generalColumns({ listing_ids: viewModel.filterModel.full_test_bulletins.map(option => option.listing_id) })
                    : Promise.resolve([]);
            },
            observers:    [
                (newValue) => {
                    if (newValue.length) {
                        viewModel.filterModel.full_general_columns = this.general_columns.instance.getSelectedOptions();
                    }
                },
            ],
        };

        this.bulletin_fields_columns = {
            type:         'select-box',
            key:          'bulletin_fields_columns',
            label:        'form.field.chose-results',
            size:         6,
            required:     false,
            remoteSource: () => {
                return viewModel.filterModel.full_test_bulletins && viewModel.filterModel.full_test_bulletins.length > 0
                    ? this.dynamicListsRepository.testBulletinsFields({ bulletins_ids: viewModel.filterModel.full_test_bulletins.map(option => option.id) })
                    : Promise.resolve([]);
            },
            observers:    [
                (newValue) => {
                    if (newValue.length) {
                        viewModel.filterModel.full_bulletin_fields_columns = this.bulletin_fields_columns.instance.getSelectedOptions();
                    }
                },
            ],
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => {
                if (viewModel.filterModel.general_columns.length === 0 && viewModel.filterModel.bulletin_fields_columns.length === 0) {
                    this.general_columns.instance.selectAll();
                    this.bulletin_fields_columns.instance.selectAll();
                    viewModel.filterModel.full_general_columns         = this.general_columns.options;
                    viewModel.filterModel.full_bulletin_fields_columns = this.bulletin_fields_columns.options;
                }

                this.eventAggregator.publish('datatable-must-be-reloaded', {
                    listingId: viewModel.listingId,
                    criteria:  viewModel.filterModel,
                });
            },
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.test_number_from, this.test_number_to, this.concession_id, this.entity_id, this.team_id],
            [this.highway_id, this.intervention_type_id],
            [this.lot_intervention_id, this.lot_construction_id, this.material_type_id],
            [this.date_from, this.date_to], // TODO: ADD SUBLANCO, LOCAL AE/E
            [this.collection_pk, this.pk_type_id, this.external_reference],
            [this.bm_approved_working_formula_id, this.control_type_id, this.sample_collection_id, this.part_zone_application_id],
            [this.sector_id, this.layer_id, this.branch_id, this.edge_id],
            [this.application_id, this.validation_status, this.inspection_status, this.concessionary_status],
            [this.test_bulletins],
            [this.general_columns, this.bulletin_fields_columns],
            [this.buttons],
        ];
    }
}
