import { bindable, inject }           from 'aurelia-framework';
import { I18N }                       from 'aurelia-i18n';
import { PLATFORM }                   from 'aurelia-pal';
import { activationStrategy }         from 'aurelia-router';
import { BaseListViewModel }          from 'base-list-view-model';
import { DynamicListsRepository }     from 'modules/dynamic-lists/services/repository';
import { FilterFormSchema }           from 'modules/dynamic-lists/tests-listings/filter-form-schema';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { AppContainer }               from 'resources/services/app-container';
import { Downloader }                 from 'resources/services/downloader';

@inject(AppContainer, LotInterventionsRepository, DynamicListsRepository, Downloader, I18N, FilterFormSchema)
export class BaseTestListingsList extends BaseListViewModel {

    listingId = 'listings';

    routePrefix      = 'dynamic-listings';
    permissionPrefix = this.routePrefix;

    @bindable headerTitle;
    @bindable newRecordRoute;
    @bindable newRecordRouteParams;
    @bindable repository;
    @bindable datatable;

    columns = [];
    type    = null;

    constructor(appContainer, lotInterventionsRepository, repository, downloader, i18N, filterFormSchema) {
        super(appContainer, lotInterventionsRepository);

        this.repository       = repository;
        this.downloader       = downloader;
        this.i18N             = i18N;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/dynamic-lists/tests-listings/index.html');
    }

    /**
     * Determines activation strategy
     *
     * @return {'replace'}
     */
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    /**
     * Handles canActivate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async canActivate(params) {
        return this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.filterModel  = await this.filterFormSchema.model(this);
        this.filterSchema = await this.filterFormSchema.schema(this, this.appContainer.authenticatedUser.isBuilder);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search: async (criteria) => await this.search(criteria),
            },
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportTestListing(),
                    visible: () => this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
                },
            ],
            actions:            [],
            actionsContextMenu: false,
            showToggleColumns:  false,
            selectable:         true,
            destroySelected:    false,
            showFilter:         false,
            sorting:            [],
            columns:            this.columns,
        };
    }

    async search(criteria) {
        return await this.repository.searchTests(criteria, this.type).then(response => {
            if (response.error) {
                throw response.error;
            }

            if (response.columns) {
                this.columns.splice(0, this.columns.length);
                this.columns.push(...response.columns);
            }

            return response;
        })
    }

    /**
     * Exports the listing to Excel
     */
    exportTestListing() {
        const filename = this.type === 'lab' ? 'form.field.laboratory' : 'form.field.in-situ-abbrev';
        this.repository.exportByType(this.filterModel, this.type).then(response => this.downloader.download(response, this.i18N.tr(filename), 'xlsx'));
    }
}
